import request from "@/utils/request";

// -------------------------------------------------------------- 农作物 -------------------------------------------------------------- //

// 字典-dictType:类型
export function dictDataPage(params, noLoading) {
  return request({
    url: "/admin-api/system/dict-data/page",
    method: "GET",
    params,
    noLoading
  });
}

// 获取验证图片  以及token
export function reqGet(data) {
  return request({
    url: "/admin-api/system/captcha/get",
    method: "post",
    data,
    isNoFail: 1
  });
}

// 滑动或者点选验证
export function reqCheck(data) {
  return request({
    url: "/admin-api/system/captcha/check",
    method: "post",
    data,
  });
}

// 发送验证码
export function sendSmsCode(data) {
  return request({
    url: "/admin-api/system/auth/send-sms-code",
    method: "post",
    data
  });
}

// 使用账号密码登录
export function authLogin(data) {
  return request({
    url: "/admin-api/system/auth/login",
    method: "POST",
    data
  });
}

// 获得登录用户的菜单列表
export function listMenus(params) {
  return request({
    url: "/admin-api/system/auth/list-menus",
    method: "GET",
    params
  });
}

// 获取登录用户信息
export function profileGet(params) {
  return request({
    url: "/admin-api/system/user/profile/gets",
    method: "GET",
    params
  });
}

// 管理端-统计
export function dataStatistics(data) {
  return request({
    url: "/admin-api/web/dataStatistics/dataStatistics",
    method: "post",
    data
  });
}

// 管理端-统计 男女比例
export function dataStatistics2(data) {
  return request({
    url: "/admin-api/web/dataStatistics/dataStatistics2",
    method: "post",
    data
  });
}

// 管理端-统计 学历比例
export function dataStatistics3(data) {
  return request({
    url: "/admin-api/web/dataStatistics/dataStatistics3",
    method: "post",
    data,
    noTen: 1
  });
}

// 管理端-统计 薪资比例
export function dataStatistics4(data) {
  return request({
    url: "/admin-api/web/dataStatistics/dataStatistics4",
    method: "post",
    data
  });
}

// 管理端-统计 企业投递
export function dataStatistics5(data) {
  return request({
    url: "/admin-api/web/dataStatistics/dataStatistics5",
    method: "post",
    data
  });
}

// 管理端-统计 岗位投递
export function dataStatistics6(data) {
  return request({
    url: "/admin-api/web/dataStatistics/dataStatistics6",
    method: "post",
    data,
    noTen: 1
  });
}

// 管理端-统计 岗位数量
export function dataStatistics7(params) {
  return request({
    url: "/admin-api/web/dataStatistics/dataStatistics7",
    method: "GET",
    params
  });
}

// 广告-后台管理
export function guanggaos(params) {
  return request({
    url: "/admin-api/web/enter/guanggaos",
    method: "GET",
    params
  });
}

// 广告-修改广告
export function guanggaoupdate(data) {
  return request({
    url: "/admin-api/web/enter/guanggaoupdate",
    method: "POST",
    data
  });
}

// 广告-新增广告
export function guanggaocreates(data) {
  return request({
    url: "/admin-api/web/enter/creates",
    method: "POST",
    data
  });
}

// 广告-删除广告
export function guanggaodelect(params) {
  return request({
    url: "/admin-api/web/enter/guanggaodelect",
    method: "delete",
    params
  });
}

// 企业管理列表
export function enterprisePage(params) {
  return request({
    url: "/admin-api/web/enter/page",
    method: "GET",
    params
  });
}

// 新增租户
export function tenantCreate(data) {
  return request({
    url: "/admin-api/system/tenant/creates",
    method: "POST",
    data
  });
}

// 新增企业基本信息
export function enterpriseCreate(data) {
  return request({
    url: "/admin-api/web/enter/create",
    method: "POST",
    data
  });
}

// 企业管理-修改
export function enterpriseUpdate(data) {
  return request({
    url: "/admin-api/web/enter/update",
    method: "PUT",
    data
  });
}

// 企业管理-删除
export function enterpriseDelete(params) {
  return request({
    url: "/admin-api/web/enter/delete",
    method: "delete",
    params
  });
}

// 求职者列表
export function seekersPage(params) {
  return request({
    url: "/admin-api/web/seekers/page",
    method: "GET",
    params
  });
}

// 会员中心
export function sAdminPage(params) {
  return request({
    url: "/admin-api/web/s-admin/page",
    method: "GET",
    params
  });
}

// 岗位管理列表
// export function adminPostPage(params) {
//   return request({
//     url: "/admin-api/system/admin-post/page",
//     method: "GET",
//     params
//   });
// }
export function adminPostPage(params) {
  return request({
    url: "/admin-api/web/position/pageas",
    method: "GET",
    params
  });
}

export function adminPostLists(params) {
  return request({
    url: "/admin-api/web/position/lists",
    method: "GET",
    params
  });
}

// 岗位管理-改变状态
export function positionupdateState(data) {
  return request({
    url: "/admin-api/web/position/updateState",
    method: "PUT",
    data
  });
}

// 岗位管理新增
// export function adminPostCreate(data) {
//   return request({
//     url: "/admin-api/system/admin-post/create",
//     method: "post",
//     data
//   });
// }

// 岗位管理新增
export function positionCreate(data) {
  return request({
    url: "/admin-api/web/position/create",
    method: "post",
    data
  });
}

// 岗位管理修改
export function positionUpdate(data) {
  return request({
    url: "/admin-api/web/position/update",
    method: "PUT",
    data
  });
}

// 岗位管理删除
export function positionDelete(params) {
  return request({
    url: "/admin-api/web/position/delete",
    method: "delete",
    params
  });
}

// 获取部门列表
export function deptList(params) {
  return request({
    url: "/admin-api/system/dept/list-all-simple",
    method: "GET",
    params
  });
}

// hr-管理分页
export function userPage(params) {
  return request({
    // url: "/admin-api/system/user/page",
    url: "/admin-api/web/s-admin/pages",
    method: "GET",
    params
  });
}

// hr-修改状态
// export function hrUpdateStatus(data) {
//   return request({
//     url: "/admin-api/system/user/update-status",
//     method: "PUT",
//     data
//   });
// }
export function hrUpdateStatus(data) {
  return request({
    url: "/admin-api/web/s-admin/update-status",
    method: "PUT",
    data
  });
}

// hr-新增
export function userCreate(data) {
  return request({
    url: "/admin-api/system/user/create",
    method: "post",
    data
  });
}

// hr-修改
// export function userUpdate(data) {
//   return request({
//     url: "/admin-api/system/user/update",
//     method: "PUT",
//     data
//   });
// }
export function userUpdate(data) {
  return request({
    url: "/admin-api/web/s-admin/adminUpdate",
    method: "PUT",
    data
  });
}

// hr-删除
// export function userDelete(params) {
//   return request({
//     url: "/admin-api/system/user/delete",
//     method: "delete",
//     params
//   });
// }
export function userDelete(params) {
  return request({
    url: "/admin-api/web/s-admin/adminDelete",
    method: "delete",
    params
  });
}

// 重置密码
// export function userUpdatePassword(data) {
//   return request({
//     url: "/admin-api/system/user/update-password",
//     method: "PUT",
//     data
//   });
// }
export function userUpdatePassword(data) {
  return request({
    url: "/admin-api/web/s-admin/update-password",
    method: "PUT",
    data
  });
}

// 通知中心分页
export function notifyMessage(params) {
  return request({
    url: "/admin-api/system/notify-message/my-page",
    method: "GET",
    params
  });
}

// 通知中心--设置已读
export function notifyMessageUpdateRead(id) {
  return request({
    url: `/admin-api/system/notify-message/update-read?ids=${id}`,
    method: "PUT"
  });
}

// 简历库列表
// export function seekersPagess(params) {
//   return request({
//     url: "/admin-api/web/seekers/pagess",
//     method: "GET",
//     params
//   });
// }

// 简历库列表
export function seekersPagess(params) {
  return request({
    url: "/admin-api/web/seekers/searchPage",
    method: "GET",
    params
  });
}

// 简历库列表--导出
export function seekersGetResExpw(params) {
  return request({
    url: "admin-api/web/seekers/getResExpw",
    method: "GET",
    params
  });
}

// 从事职业列表
export function positionPost(params) {
  return request({
    url: "/admin-api/web/position/post",
    method: "GET",
    params
  });
}

// 简历库详情
export function seekersGetResExp(params) {
  return request({
    url: "/admin-api/web/seekers/getResExp",
    method: "GET",
    params
  });
}

// 评论区查看
export function commentPage(params) {
  return request({
    url: "/admin-api/web/comment/pages",
    method: "GET",
    params
  });
}

// 评论区审核
export function commentUpdate(params) {
  return request({
    url: "/admin-api/web/comment/update",
    method: "PUT",
    params
  });
}

// 评论区删除
export function commentDelete(params) {
  return request({
    url: "/admin-api/web/comment/delete",
    method: "delete",
    params
  });
}

// 企业租户列表
export function tenantPage(params) {
  return request({
    url: "/admin-api/system/tenant/page",
    method: "GET",
    params
  });
}

// 直播列表
export function zhiboPage(params) {
  return request({
    url: "/admin-api/web/zhibo/page",
    method: "GET",
    params
  });
}

// 直播列表-新增
export function zhiboCreate(data) {
  return request({
    url: "/admin-api/web/zhibo/create",
    method: "post",
    data
  });
}

// 直播列表-修改
export function zhiboUpdate(data) {
  return request({
    url: "/admin-api/web/zhibo/update",
    method: "PUT",
    data
  });
}

// 直播列表-删除
export function zhiboDelete(params) {
  return request({
    url: "/admin-api/web/zhibo/delete",
    method: "delete",
    params
  });
}

// 所属行业
export function zidianPage(params, noLoading) {
  return request({
    url: "/admin-api/web/zidian/page",
    method: "GET",
    params,
    noLoading
  });
}

// 公司信息
export function aboutList(params) {
  return request({
    url: "/admin-api/web/about/list",
    method: "GET",
    params
  });
}

// 公司信息-修改
export function aboutUpdata(data) {
  return request({
    url: "/admin-api/web/about/updata",
    method: "POST",
    data
  });
}

// 管理端用户列表
// export function userPage(params) {
//   return request({
//     url: "/admin-api/system/user/page",
//     method: "GET",
//     params
//   });
// }

// 所属行业-分页
export function zidianPages(params) {
  return request({
    url: "/admin-api/web/zidian/pages",
    method: "GET",
    params
  });
}

// 所属行业-新增
export function zidianCreate(data) {
  return request({
    url: "/admin-api/web/zidian/create",
    method: "post",
    data
  });
}

// 所属行业-修改
export function zidianUpdate(data) {
  return request({
    url: "/admin-api/web/zidian/update",
    method: "PUT",
    data
  });
}

// 所属行业-删除
export function zidianDelete(params) {
  return request({
    url: "/admin-api/web/zidian/delete",
    method: "delete",
    params
  });
}

// 获取上级部门
export function getnatures(params) {
  return request({
    url: "/admin-api/web/department/pageas",
    method: "GET",
    params,
    noLoad: true
  });
}

// 全部已读
export function updateAllRead(params) {
  return request({
    url: "/admin-api/system/notify-message/update-all-read",
    method: "PUT",
    params
  });
}

export function seekersupdatestate(params) {
  return request({
    url: "/admin-api/web/seekers/updatestate",
    method: "GET",
    params
  });
}


// ---------------- 二期
// 获得操作指南分页
export function operationManualList(params) {
  return request({
    url: "/admin-api/web/operation-manual/page",
    method: "GET",
    params
  });
}

// 获得操作指南
export function operationManualGet(params) {
  return request({
    url: "/admin-api/web/operation-manual/get",
    method: "GET",
    params
  });
}

// 创建操作指南
export function operationManualCreate(data) {
  return request({
    url: "/admin-api/web/operation-manual/create",
    method: "POST",
    data
  });
}

// 更新操作指南
export function operationManualUpdate(data) {
  return request({
    url: "/admin-api/web/operation-manual/update",
    method: "PUT",
    data
  });
}

// 删除操作指南
export function operationManualDelete(params) {
  return request({
    url: "/admin-api/web/operation-manual/delete",
    method: "delete",
    params
  });
}

// 获得办事指南分页
export function workManualList(params) {
  return request({
    url: "/admin-api/web/work-manual/page",
    method: "GET",
    params
  });
}

// 获得办事指南
export function workManualGet(params) {
  return request({
    url: "/admin-api/web/work-manual/get",
    method: "GET",
    params
  });
}

// 创建办事指南
export function workManualCreate(data) {
  return request({
    url: "/admin-api/web/work-manual/create",
    method: "POST",
    data
  });
}

// 更新办事指南
export function workManualUpdate(data) {
  return request({
    url: "/admin-api/web/work-manual/update",
    method: "PUT",
    data
  });
}

// 删除办事指南
export function workManualDelete(params) {
  return request({
    url: "/admin-api/web/work-manual/delete",
    method: "delete",
    params
  });
}

// 获得职场指南分页
export function jobManualList(params) {
  return request({
    url: "/admin-api/web/job-manual/page",
    method: "GET",
    params
  });
}

// 获得职场指南
export function jobManualGet(params) {
  return request({
    url: "/admin-api/web/job-manual/get",
    method: "GET",
    params
  });
}

// 创建职场指南
export function jobManualCreate(data) {
  return request({
    url: "/admin-api/web/job-manual/create",
    method: "POST",
    data
  });
}

// 更新职场指南
export function jobManualUpdate(data) {
  return request({
    url: "/admin-api/web/job-manual/update",
    method: "PUT",
    data
  });
}

// 删除职场指南
export function jobManualDelete(params) {
  return request({
    url: "/admin-api/web/job-manual/delete",
    method: "delete",
    params
  });
}

// 获得下载专区分页
export function downloadCenterList(params) {
  return request({
    url: "/admin-api/web/download-center/page",
    method: "GET",
    params
  });
}

// 获得下载专区
export function downloadCenterGet(params) {
  return request({
    url: "/admin-api/web/download-center/get",
    method: "GET",
    params
  });
}

// 创建下载专区
export function downloadCenterCreate(data) {
  return request({
    url: "/admin-api/web/download-center/create",
    method: "POST",
    data
  });
}

// 更新下载专区
export function downloadCenterUpdate(data) {
  return request({
    url: "/admin-api/web/download-center/update",
    method: "PUT",
    data
  });
}

// 删除下载专区
export function downloadCenterDelete(params) {
  return request({
    url: "/admin-api/web/download-center/delete",
    method: "delete",
    params
  });
}

// 获得热门资讯分页
export function newsList(params) {
  return request({
    url: "/admin-api/web/news/page",
    method: "GET",
    params
  });
}

// 获得热门资讯
export function newsGet(params) {
  return request({
    url: "/admin-api/web/news/get",
    method: "GET",
    params
  });
}

// 创建热门资讯
export function newsCreate(data) {
  return request({
    url: "/admin-api/web/news/create",
    method: "POST",
    data
  });
}

// 更新热门资讯
export function newsUpdate(data) {
  return request({
    url: "/admin-api/web/news/update",
    method: "PUT",
    data
  });
}

// 删除热门资讯
export function newsDelete(params) {
  return request({
    url: "/admin-api/web/news/delete",
    method: "delete",
    params
  });
}

// 获得人才政策分页
export function talentPolicyList(params) {
  return request({
    url: "/admin-api/web/talent-policy/page",
    method: "GET",
    params
  });
}

// 获得人才政策
export function talentPolicyGet(params) {
  return request({
    url: "/admin-api/web/talent-policy/get",
    method: "GET",
    params
  });
}

// 创建人才政策
export function talentPolicyCreate(data) {
  return request({
    url: "/admin-api/web/talent-policy/create",
    method: "POST",
    data
  });
}

// 更新人才政策
export function talentPolicyUpdate(data) {
  return request({
    url: "/admin-api/web/talent-policy/update",
    method: "PUT",
    data
  });
}

// 删除人才政策
export function talentPolicyDelete(params) {
  return request({
    url: "/admin-api/web/talent-policy/delete",
    method: "delete",
    params
  });
}

// 获得三项岗位-报名资料分页
export function postResourceList(params) {
  return request({
    url: "/admin-api/web/post-resource/page",
    method: "GET",
    params
  });
}

// 获得三项岗位-报名资料
export function postResourceGet(params) {
  return request({
    url: "/admin-api/web/post-resource/get",
    method: "GET",
    params
  });
}

// 创建三项岗位-报名资料
export function postResourceCreate(data) {
  return request({
    url: "/admin-api/web/post-resource/create",
    method: "POST",
    data
  });
}

// 更新三项岗位-报名资料
export function postResourceUpdate(data) {
  return request({
    url: "/admin-api/web/post-resource/update",
    method: "PUT",
    data
  });
}

// 删除三项岗位-报名资料
export function postResourceDelete(params) {
  return request({
    url: "/admin-api/web/post-resource/delete",
    method: "delete",
    params
  });
}

// 获得三项岗位-报名资料-子分类分页
export function postSubtypeList(params) {
  return request({
    url: "/admin-api/web/post-subtype/page",
    method: "GET",
    params
  });
}

// 获得三项岗位-报名资料-子分类
export function postSubtypeGet(params) {
  return request({
    url: "/admin-api/web/post-subtype/get",
    method: "GET",
    params
  });
}

// 创建三项岗位-报名资料-子分类
export function postSubtypeCreate(data) {
  return request({
    url: "/admin-api/web/post-subtype/create",
    method: "POST",
    data
  });
}

// 更新三项岗位-报名资料-子分类
export function postSubtypeUpdate(data) {
  return request({
    url: "/admin-api/web/post-subtype/update",
    method: "PUT",
    data
  });
}

// 删除三项岗位-报名资料-子分类
export function postSubtypeDelete(params) {
  return request({
    url: "/admin-api/web/post-subtype/delete",
    method: "delete",
    params
  });
}

// 获得三项岗位-报名资料-三级分类分页
export function postThirdtypeList(params) {
  return request({
    url: "/admin-api/web/post-thirdtype/page",
    method: "GET",
    params
  });
}

// 获得三项岗位-报名资料-三级分类
export function postThirdtypeGet(params) {
  return request({
    url: "/admin-api/web/post-thirdtype/get",
    method: "GET",
    params
  });
}

// 创建三项岗位-报名资料-三级分类
export function postThirdtypeCreate(data) {
  return request({
    url: "/admin-api/web/post-thirdtype/create",
    method: "POST",
    data
  });
}

// 更新三项岗位-报名资料-三级分类
export function postThirdtypeUpdate(data) {
  return request({
    url: "/admin-api/web/post-thirdtype/update",
    method: "PUT",
    data
  });
}

// 删除三项岗位-报名资料-三级分类
export function postThirdtypeDelete(params) {
  return request({
    url: "/admin-api/web/post-thirdtype/delete",
    method: "delete",
    params
  });
}

// 获得三项岗位-栏目简介分页
export function postIntroductionList(params) {
  return request({
    url: "/admin-api/web/post-introduction/page",
    method: "GET",
    params
  });
}

// 获得三项岗位-栏目简介
export function postIntroductionGet(params) {
  return request({
    url: "/admin-api/web/post-introduction/get",
    method: "GET",
    params
  });
}

// 创建三项岗位-栏目简介
export function postIntroductionCreate(data) {
  return request({
    url: "/admin-api/web/post-introduction/create",
    method: "POST",
    data
  });
}

// 更新三项岗位-栏目简介
export function postIntroductionUpdate(data) {
  return request({
    url: "/admin-api/web/post-introduction/update",
    method: "PUT",
    data
  });
}

// 删除三项岗位-栏目简介
export function postIntroductionDelete(params) {
  return request({
    url: "/admin-api/web/post-introduction/delete",
    method: "delete",
    params
  });
}

// 获得人才培养-课程专栏分页
export function courseList(params) {
  return request({
    url: "/admin-api/web/course/page",
    method: "GET",
    params
  });
}

// 获得人才培养-课程专栏
export function courseGet(params) {
  return request({
    url: "/admin-api/web/course/get",
    method: "GET",
    params
  });
}

// 创建人才培养-课程专栏
export function courseCreate(data) {
  return request({
    url: "/admin-api/web/course/create",
    method: "POST",
    data
  });
}

// 更新人才培养-课程专栏
export function courseUpdate(data) {
  return request({
    url: "/admin-api/web/course/update",
    method: "PUT",
    data
  });
}

// 删除人才培养-课程专栏
export function courseDelete(params) {
  return request({
    url: "/admin-api/web/course/delete",
    method: "delete",
    params
  });
}

// 获得同等学力分页
export function enrollList(params) {
  return request({
    url: "/admin-api/web/enroll/page",
    method: "GET",
    params
  });
}

// 获得同等学力
export function enrollGet(params) {
  return request({
    url: "/admin-api/web/enroll/get",
    method: "GET",
    params
  });
}

// 创建同等学力
export function enrollCreate(data) {
  return request({
    url: "/admin-api/web/enroll/create",
    method: "POST",
    data
  });
}

// 更新同等学力
export function enrollUpdate(data) {
  return request({
    url: "/admin-api/web/enroll/update",
    method: "PUT",
    data
  });
}

// 删除同等学力
export function enrollDelete(params) {
  return request({
    url: "/admin-api/web/enroll/delete",
    method: "delete",
    params
  });
}

// 获得继续教育分页
export function continuingEducationList(params) {
  return request({
    url: "/admin-api/web/continuing-education/page",
    method: "GET",
    params
  });
}

// 获得继续教育
export function continuingEducationGet(params) {
  return request({
    url: "/admin-api/web/continuing-education/get",
    method: "GET",
    params
  });
}

// 创建继续教育
export function continuingEducationCreate(data) {
  return request({
    url: "/admin-api/web/continuing-education/create",
    method: "POST",
    data
  });
}

// 更新继续教育
export function continuingEducationUpdate(data) {
  return request({
    url: "/admin-api/web/continuing-education/update",
    method: "PUT",
    data
  });
}

// 删除继续教育
export function continuingEducationDelete(params) {
  return request({
    url: "/admin-api/web/continuing-education/delete",
    method: "delete",
    params
  });
}

// 获得附件分页
export function attachmentPage(params) {
  return request({
    url: "/admin-api/web/attachment/page",
    method: "GET",
    params
  });
}

// 获得附件列表
export function attachmentList(params) {
  return request({
    url: "/admin-api/web/attachment/list",
    method: "GET",
    params
  });
}

// 获得附件
export function attachmentGet(params) {
  return request({
    url: "/admin-api/web/attachment/get",
    method: "GET",
    params
  });
}

// 创建附件
export function attachmentCreate(data) {
  return request({
    url: "/admin-api/web/attachment/create",
    method: "POST",
    data
  });
}

// 更新附件
export function attachmentUpdate(data) {
  return request({
    url: "/admin-api/web/attachment/update",
    method: "PUT",
    data
  });
}

// 删除附件
export function attachmentDelete(params) {
  return request({
    url: "/admin-api/web/attachment/delete",
    method: "delete",
    params
  });
}

// 获得报名详情分页
export function enrollDetailPage(params) {
  return request({
    url: "/admin-api/web/enroll-detail/page",
    method: "GET",
    params
  });
}

// 获得报名详情
export function enrollDetailGet(params) {
  return request({
    url: "/admin-api/web/enroll-detail/get",
    method: "GET",
    params
  });
}

// 创建报名详情
export function enrollDetailCreate(data) {
  return request({
    url: "/admin-api/web/enroll-detail/create",
    method: "POST",
    data
  });
}

// 更新报名详情
export function enrollDetailUpdate(data) {
  return request({
    url: "/admin-api/web/enroll-detail/update",
    method: "PUT",
    data
  });
}

// 删除报名详情
export function enrollDetailDelete(params) {
  return request({
    url: "/admin-api/web/enroll-detail/delete",
    method: "delete",
    params
  });
}

// 获得职称评审分页
export function titleReviewPage(params) {
  return request({
    url: "/admin-api/web/title-review/page",
    method: "GET",
    params
  });
}

// 获得职称评审
export function titleReviewGet(params) {
  return request({
    url: "/admin-api/web/title-review/get",
    method: "GET",
    params
  });
}

// 创建职称评审
export function titleReviewCreate(data) {
  return request({
    url: "/admin-api/web/title-review/create",
    method: "POST",
    data
  });
}

// 更新职称评审
export function titleReviewUpdate(data) {
  return request({
    url: "/admin-api/web/title-review/update",
    method: "PUT",
    data
  });
}

// 删除职称评审
export function titleReviewDelete(params) {
  return request({
    url: "/admin-api/web/title-review/delete",
    method: "delete",
    params
  });
}

// 获得职称评审tab分页
export function titleReviewTabPage(params) {
  return request({
    url: "/admin-api/web/title-review-tab/page",
    method: "GET",
    params
  });
}

// 获得职称评审tab
export function titleReviewTabGet(params) {
  return request({
    url: "/admin-api/web/title-review-tab/get",
    method: "GET",
    params
  });
}

// 创建职称评审tab
export function titleReviewTabCreate(data) {
  return request({
    url: "/admin-api/web/title-review-tab/create",
    method: "POST",
    data
  });
}

// 更新职称评审tab
export function titleReviewTabUpdate(data) {
  return request({
    url: "/admin-api/web/title-review-tab/update",
    method: "PUT",
    data
  });
}

// 删除职称评审tab
export function titleReviewTabDelete(params) {
  return request({
    url: "/admin-api/web/title-review-tab/delete",
    method: "delete",
    params
  });
}

// 获得职称评审申报材料分页
export function titleReviewAttachPage(params) {
  return request({
    url: "/admin-api/web/title-review-attach/page",
    method: "GET",
    params
  });
}

// 获得职称评审申报材料
export function titleReviewAttachGet(params) {
  return request({
    url: "/admin-api/web/title-review-attach/get",
    method: "GET",
    params
  });
}

// 创建职称评审申报材料
export function titleReviewAttachCreate(data) {
  return request({
    url: "/admin-api/web/title-review-attach/create",
    method: "POST",
    data
  });
}

// 更新职称评审申报材料
export function titleReviewAttachUpdate(data) {
  return request({
    url: "/admin-api/web/title-review-attach/update",
    method: "PUT",
    data
  });
}

// 删除职称评审申报材料
export function titleReviewAttachDelete(params) {
  return request({
    url: "/admin-api/web/title-review-attach/delete",
    method: "delete",
    params
  });
}

// 获得职称评审申报记录分页
export function titleReviewRecordPage(params) {
  return request({
    url: "/admin-api/web/title-review-record/page",
    method: "GET",
    params
  });
}

// 获得职称评审申报记录
export function titleReviewRecordGet(params) {
  return request({
    url: "/admin-api/web/title-review-record/get",
    method: "GET",
    params
  });
}

// 创建职称评审申报记录
export function titleReviewRecordCreate(data) {
  return request({
    url: "/admin-api/web/title-review-record/create",
    method: "POST",
    data
  });
}

// 更新职称评审申报记录
export function titleReviewRecordUpdate(data) {
  return request({
    url: "/admin-api/web/title-review-record/update",
    method: "PUT",
    data
  });
}

// 删除职称评审申报记录
export function titleReviewRecordDelete(params) {
  return request({
    url: "/admin-api/web/title-review-record/delete",
    method: "delete",
    params
  });
}

// 获取会议室分页
export function meetingRoomPage(params) {
  return request({
    url: "/admin-api/web/meeting-room/page",
    method: "GET",
    params
  });
}

// 获得会议室
export function meetingRoomGet(params) {
  return request({
    url: "/admin-api/web/meeting-room/get",
    method: "GET",
    params
  });
}

// 创建会议室
export function meetingRoomCreate(data) {
  return request({
    url: "/admin-api/web/meeting-room/create",
    method: "POST",
    data
  });
}

// 更新会议室
export function meetingRoomUpdate(data) {
  return request({
    url: "/admin-api/web/meeting-room/update",
    method: "PUT",
    data
  });
}

// 删除会议室
export function meetingRoomDelete(params) {
  return request({
    url: "/admin-api/web/meeting-room/delete",
    method: "delete",
    params
  });
}

// 获取会议预约分页
export function meetingBookPage(params) {
  return request({
    url: "/admin-api/web/meeting-book/page",
    method: "GET",
    params
  });
}

// 获得会议预约
export function meetingBookGet(params) {
  return request({
    url: "/admin-api/web/meeting-book/get",
    method: "GET",
    params
  });
}

// 创建会议预约
export function meetingBookCreate(data) {
  return request({
    url: "/admin-api/web/meeting-book/create",
    method: "POST",
    data
  });
}

// 更新会议预约
export function meetingBookUpdate(data) {
  return request({
    url: "/admin-api/web/meeting-book/update",
    method: "PUT",
    data
  });
}

// 删除会议预约
export function meetingBookDelete(params) {
  return request({
    url: "/admin-api/web/meeting-book/delete",
    method: "delete",
    params
  });
}

// 获取部门列表
export function systemDeptList(params) {
  return request({
    url: "/admin-api/system/dept/list",
    method: "GET",
    params
  });
}

// 获得部门
export function systemDeptGet(params) {
  return request({
    url: "/admin-api/system/dept/get",
    method: "GET",
    params
  });
}

// 创建部门
export function systemDeptCreate(data) {
  return request({
    url: "/admin-api/system/dept/create",
    method: "POST",
    data
  });
}

// 更新部门
export function systemDeptUpdate(data) {
  return request({
    url: "/admin-api/system/dept/update",
    method: "PUT",
    data
  });
}

// 删除部门
export function systemDeptDelete(params) {
  return request({
    url: "/admin-api/system/dept/delete",
    method: "delete",
    params
  });
}

// 获得用户分页列表
export function systemUserPage(params) {
  return request({
    url: "/admin-api/system/user/page",
    method: "GET",
    params
  });
}

// 获得用户
export function systemUserGet(params) {
  return request({
    url: "/admin-api/system/user/get",
    method: "GET",
    params
  });
}

// 创建用户
export function systemUserCreate(data) {
  return request({
    url: "/admin-api/system/user/createNoValidate",
    method: "POST",
    data
  });
}

// 更新用户
export function systemUserUpdate(data) {
  return request({
    url: "/admin-api/system/user/updateNoValidate",
    method: "PUT",
    data
  });
}

// 删除用户
export function systemUserDelete(params) {
  return request({
    url: "/admin-api/system/user/delete",
    method: "delete",
    params
  });
}

// 重置用户密码
export function systemUserUpdatePassword(data) {
  return request({
    url: "/admin-api/system/user/update-password",
    method: "PUT",
    data
  });
}

// 用户修改个人密码
export function systemUserProfileUpdatePassword(data) {
  return request({
    url: "/admin-api/system/user/profile/update-password",
    method: "PUT",
    data
  });
}

// 管理员审核
export function adminCheck(data) {
  return request({
    url: "/admin-api/web/title-review-record/adminCheck",
    method: "PUT",
    data
  });
}

// 获得三项岗位-banner分页
export function postBannerPage(params) {
  return request({
    url: "/admin-api/web/post-banner/page",
    method: "GET",
    params
  });
}

// 获得三项岗位-banner
export function postBannerGet(params) {
  return request({
    url: "/admin-api/web/post-banner/get",
    method: "GET",
    params
  });
}

// 创建三项岗位-banner
export function postBannerCreate(data) {
  return request({
    url: "/admin-api/web/post-banner/create",
    method: "POST",
    data
  });
}

// 更新三项岗位-banner
export function postBannerUpdate(data) {
  return request({
    url: "/admin-api/web/post-banner/update",
    method: "PUT",
    data
  });
}
