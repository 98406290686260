<template>
  <div class="navbar" ref="navbarRef">
    <div class="logo">
      <img src="@/assets/logoTitle.png" />
      <span>管理端</span>
    </div>
    <el-menu
      :default-active="activeMenu"
      unique-opened
      background-color="rgba(0,0,0,0)"
      text-color="#fff"
      active-text-color="#fff"
    >
      <el-menu-item index="/">
        <img v-if="activeMenu == '/'" :src="ossURL + '/home2.png'" />
        <img v-else :src="ossURL + '/home2s.png'" />
        <span slot="title" style="margin-left: 5px;"><router-link to="/">数据统计</router-link></span>
      </el-menu-item>
      <el-submenu index="2">
        <template slot="title">
          <img :src="ossURL + '/home3.png'" />
          <span slot="title" style="margin-left: 5px;">招聘管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/enterprise_examine"
            ><router-link to="/enterprise_examine"
              >企业信息审核</router-link
            ></el-menu-item
          >
          <el-menu-item index="/enterprise_manage"
            ><router-link to="/enterprise_manage"
              >企业管理</router-link
            ></el-menu-item
          >
          <el-menu-item index="/key_talents"
            ><router-link to="/key_talents"
              >重点人才管理</router-link
            ></el-menu-item
          >
          <el-menu-item index="/resume_library"
            ><router-link to="/resume_library"
              >简历库</router-link
            ></el-menu-item
          >
          <el-menu-item index="/post_manage"
            ><router-link to="/post_manage">职位管理</router-link></el-menu-item
          >
          <el-menu-item index="/message_review"
            ><router-link to="/message_review"
              >留言审核</router-link
            ></el-menu-item
          >
          <el-menu-item index="/hr"
            ><router-link to="/hr">HR管理</router-link></el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title">
          <img :src="ossURL + '/home4.png'" />
          <span slot="title" style="margin-left: 5px;">系统管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/system/aboutUs"
            ><router-link to="/system/aboutUs"
              >公司信息</router-link
            ></el-menu-item
          >
          <el-menu-item index="/system/postBanner"
            ><router-link to="/system/postBanner"
              >三项岗位Banner</router-link
            ></el-menu-item
          >
          <el-menu-item index="/system/industry"
            ><router-link to="/system/industry"
              >所属行业</router-link
            ></el-menu-item
          >
          <!-- <el-menu-item index="/system/user"><router-link to="/system/user">用户管理</router-link></el-menu-item> -->
          <el-menu-item index="/system/dict"
            ><router-link to="/system/dict">字典管理</router-link></el-menu-item
          >
          <el-submenu index="3-1">
            <template slot="title">短信管理</template>
            <el-menu-item-group>
              <el-menu-item index="/system/sms-channel"
                ><router-link to="/system/sms-channel"
                  >短信渠道</router-link
                ></el-menu-item
              >
              <el-menu-item index="/system/sms-template"
                ><router-link to="/system/sms-template"
                  >短信模板</router-link
                ></el-menu-item
              >
              <el-menu-item index="/system/sms-log"
                ><router-link to="/system/sms-log"
                  >短信日志</router-link
                ></el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="4">
        <template slot="title">
          <img style="width: 24px" src="@/assets/chen/home5.png" />
          <span slot="title" style="margin-left: 5px;">办事窗口</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/platform"
            ><router-link to="/platform">平台专区</router-link></el-menu-item
          >
          <el-menu-item index="/policyNews"
            ><router-link to="/policyNews">政策新闻</router-link></el-menu-item
          >
          <el-menu-item index="/threePositions"
            ><router-link to="/threePositions"
              >三项岗位</router-link
            ></el-menu-item
          >
          <el-menu-item index="/personneltraining"
            ><router-link to="/personneltraining"
              >课程专栏</router-link
            ></el-menu-item
          >
          <el-menu-item index="/operation"
            ><router-link to="/operation">活动报名</router-link></el-menu-item
          >
          <el-menu-item index="/enroll"
            ><router-link to="/enroll">同等学力</router-link></el-menu-item
          >
          <el-menu-item index="/continuingEducation"
            ><router-link to="/continuingEducation"
              >继续教育</router-link
            ></el-menu-item
          >
          <el-menu-item index="/titleReview"
            ><router-link to="/titleReview">职称评审</router-link></el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="5">
        <template slot="title">
          <img style="width: 24px" src="@/assets/chen/home6.png" />
          <span slot="title" style="margin-left: 5px;">会议室</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/meetingRoomManage"
            ><router-link to="/meetingRoomManage"
              >会议室管理</router-link
            ></el-menu-item
          >
          <el-menu-item index="/deptList"
            ><router-link to="/deptList">部门列表</router-link></el-menu-item
          >
          <el-menu-item index="/userList"
            ><router-link to="/userList">用户列表</router-link></el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import { ossURL } from '../utils/request'
export default {
  name: 'navbars',
  data() {
    return {
      ossURL,
      handleOpen(key, keyPath) {
        // console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        // console.log(key, keyPath);
      },
    }
  },
  computed: {
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
  },
  mounted() {
    let that = this
    that.$nextTick(() => {
      let clientHeight = that.$refs.navbarRef.clientHeight
      that.$store.commit('initClientHeight', clientHeight)
    })
  },
}
</script>

<style lang="scss" scoped>
a {
  color: #fff;
  height: 100%;
  display: inline-flex;
  width: 100%;
  align-items: center;
}

.navbar {
  width: 245px;
  height: 100%;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  background: #2057ab;
  padding-top: 55px;
  overflow-y: auto;
  overflow-x: hidden;
}

.navbar .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 105px;
}

.navbar .logo img {
  width: 135px;
  margin-right: 14px;
}

.navbar .logo span {
  display: inline-flex;
  align-items: center;
  padding: 0 13px;
  font-size: 12px;
  color: #ccd4eb;
  height: 19px;
  border-radius: 4px;
  border: 1px solid #ccd4eb;
}

::v-deep .el-menu {
  border-right: none;
}

::v-deep .el-menu-item.is-active {
  background-color: #ffffff !important;
  border-radius: 8px;

  a {
    color: #007cc4 !important;
  }
}
</style>
