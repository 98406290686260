import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "@/assets/css/css.css";
import "@/assets/css/reset.css";

import navbars from "@/components/navbars";
import headers from "@/components/headers";
Vue.component("navbars", navbars);
Vue.component("headers", headers);

import DictTag from "@/components/DictTag";
Vue.component("DictTag", DictTag);

import {
  DICT_TYPE,
  getDictDataLabel,
  getDictDatas,
  getDictDatas2
} from "@/utils/dict";
Vue.prototype.DICT_TYPE = DICT_TYPE;
Vue.prototype.getDictDataLabel = getDictDataLabel;
Vue.prototype.getDictDatas = getDictDatas;
Vue.prototype.getDictDatas2 = getDictDatas2;

import { parseTime, resetForm, handleTree } from "@/utils/ruoyi";
Vue.prototype.parseTime = parseTime;
Vue.prototype.resetForm = resetForm;
Vue.prototype.handleTree = handleTree;

import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

import * as dayjs from "dayjs";
Vue.prototype.$dayjs = dayjs;

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

store.dispatch("loadLocal");
store.dispatch("loadDictDatas");

// 路由守卫
router.beforeEach((to, from, next) => {
  let token = store.state.token;
  if (token) {
    next();
  } else {
    if (to.path === "/login") {
      next();
    } else {
      next("/login");
    }
  }
});

Vue.config.productionTip = false

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
