import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "hash",
  routes: [
    {
      path: "/",
      name: "box",
      component: () => import("@/pages/home/box"),
      meta: {
        nokeep: true,
        nav: true,
        navs: true
      },
      children: [
        {
          path: "/",
          name: "home",
          component: () => import("@/pages/home/index"),
          meta: {
            nokeep: true,
            nav: true,
            navs: true
          }
        },
        {
          path: "/login",
          name: "login",
          component: () => import("@/pages/home/login"),
          meta: {
            nokeep: true,
            nav: false,
            navs: false
          }
        },
        {
          path: "/enterprise_examine",
          name: "enterprise_examine",
          component: () => import("@/pages/home/enterprise_examine"),
          meta: {
            nokeep: true,
            nav: true,
            navs: true
          }
        },
        {
          path: "/enterprise_manage",
          name: "enterprise_manage",
          component: () => import("@/pages/home/enterprise_manage"),
          meta: {
            nokeep: true,
            nav: true,
            navs: true
          }
        },
        {
          path: "/key_talents",
          name: "key_talents",
          component: () => import("@/pages/home/key_talents"),
          meta: {
            nokeep: true,
            nav: true,
            navs: true
          }
        },
        {
          path: "/resume_library",
          name: "resume_library",
          component: () => import("@/pages/home/resume_library"),
          meta: {
            nokeep: true,
            nav: true,
            navs: true
          }
        },
        {
          path: "/post_manage",
          name: "post_manage",
          component: () => import("@/pages/home/post_manage"),
          meta: {
            nokeep: true,
            nav: true,
            navs: true
          }
        },
        {
          path: "/message_review",
          name: "message_review",
          component: () => import("@/pages/home/message_review"),
          meta: {
            nokeep: true,
            nav: true,
            navs: true
          }
        },
        {
          path: "/hr",
          name: "hr",
          component: () => import("@/pages/home/hr"),
          meta: {
            nokeep: true,
            nav: true,
            navs: true
          }
        },
        {
          path: "/notice",
          name: "notice",
          component: () => import("@/pages/home/notice"),
          meta: {
            nokeep: true,
            nav: false,
            navs: false
          }
        },
        {
          path: "/system",
          name: "system",
          component: () => import("@/pages/home/system/box"),
          meta: {
            nokeep: true,
            nav: true,
            navs: true
          },
          children: [
            {
              path: "/system/aboutUs",
              name: "aboutUs",
              component: () => import("@/pages/home/system/aboutUs"),
              meta: {
                nokeep: true,
                nav: true,
                navs: true
              }
            },
            {
              path: "/system/postBanner",
              name: "postBanner",
              component: () => import("@/pages/home/system/postBanner"),
              meta: {
                nokeep: true,
                nav: true,
                navs: true
              }
            },
            {
              path: "/system/industry",
              name: "industry",
              component: () => import("@/pages/home/system/industry"),
              meta: {
                nokeep: true,
                nav: true,
                navs: true
              }
            },
            {
              path: "/system/user",
              name: "user",
              component: () => import("@/pages/home/system/user"),
              meta: {
                nokeep: true,
                nav: true,
                navs: true
              }
            },
            {
              path: "/system/dict",
              name: "dict",
              component: () => import("@/pages/home/system/dict"),
              meta: {
                nokeep: true,
                nav: true,
                navs: true
              }
            },
            {
              path: "/system/dictData",
              name: "dictData",
              component: () => import("@/pages/home/system/dictData"),
              meta: {
                nokeep: true,
                nav: true,
                navs: true
              }
            },
            {
              path: "/system/sms-channel",
              name: "dict",
              component: () => import("@/pages/home/system/sms-channel"),
              meta: {
                nokeep: true,
                nav: true,
                navs: true
              }
            },
            {
              path: "/system/sms-template",
              name: "template",
              component: () => import("@/pages/home/system/sms-template"),
              meta: {
                nokeep: true,
                nav: true,
                navs: true
              }
            },
            {
              path: "/system/sms-log",
              name: "log",
              component: () => import("@/pages/home/system/sms-log"),
              meta: {
                nokeep: true,
                nav: true,
                navs: true
              }
            }
          ]
        }
      ]
    },
    {
      path: "/window",
      name: "window",
      component: () => import("@/pages/window/box"),
      meta: {
        nokeep: true,
        nav: true,
        navs: true
      },
      children: [
        {
          path: "/platform",
          name: "platform",
          component: () => import("@/pages/window/platform/index"),
          meta: {
            nokeep: true,
            nav: true,
            navs: true
          }
        }, {
          path: "/policyNews",
          name: "policyNews",
          component: () => import("@/pages/window/policyNews/index"),
          meta: {
            nokeep: true,
            nav: true,
            navs: true
          }
        }, {
          path: "/threePositions",
          name: "threePositions",
          component: () => import("@/pages/window/threePositions/index"),
          meta: {
            nokeep: true,
            nav: true,
            navs: true
          }
        }, {
          path: "/personneltraining",
          name: "personneltraining",
          component: () => import("@/pages/window/personneltraining.vue"),
          meta: {
            nokeep: true,
            nav: true,
            navs: true
          }
        }, {
          path: "/operation",
          name: "operation",
          component: () => import("@/pages/window/operation/index.vue"),
          meta: {
            nokeep: true,
            nav: true,
            navs: true
          }
        }, {
          path: "/enroll",
          name: "enroll",
          component: () => import("@/pages/window/enroll/index.vue"),
          meta: {
            nokeep: true,
            nav: true,
            navs: true
          }
        }, {
          path: "/continuingEducation",
          name: "continuingEducation",
          component: () => import("@/pages/window/continuingEducation/index.vue"),
          meta: {
            nokeep: true,
            nav: true,
            navs: true
          }
        }, {
          path: "/titleReview",
          name: "titleReview",
          component: () => import("@/pages/window/titleReview/index.vue"),
          meta: {
            nokeep: true,
            nav: true,
            navs: true
          }
        }
      ]
    },
    {
      path: "/meetingRoom",
      name: "meetingRoom",
      component: () => import("@/pages/meetingRoom/box"),
      meta: {
        nokeep: true,
        nav: true,
        navs: true
      },
      children: [
        {
          path: "/meetingRoomManage",
          name: "meetingRoomManage",
          component: () => import("@/pages/meetingRoom/index"),
          meta: {
            nokeep: true,
            nav: true,
            navs: true
          }
        }, {
          path: "/deptList",
          name: "deptList",
          component: () => import("@/pages/meetingRoom/deptList"),
          meta: {
            nokeep: true,
            nav: true,
            navs: true
          }
        }, {
          path: "/userList",
          name: "userList",
          component: () => import("@/pages/meetingRoom/userList"),
          meta: {
            nokeep: true,
            nav: true,
            navs: true
          }
        }
      ]
    }
  ]
});
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
