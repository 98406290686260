<template>
  <div class="header">
    <p class="name">
      早上好，{{ userInfo ? userInfo.nickname : '-' }}，欢迎使用系统
    </p>
    <div class="right">
      <div class="icon">
        <router-link to="/message_review">
          <img :src="ossURL + '/home1.png'" />
        </router-link>
      </div>
      <div class="icon" :class="{ on: !isAllRead }">
        <router-link to="/notice">
          <img :src="ossURL + '/home.png'" />
        </router-link>
      </div>
      <el-dropdown @command="handleCommand">
        <div class="user">
          <a href="javascript:;">
            <img :src="userInfo ? userInfo.avatar : '-'" />
          </a>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="changePassword">修改密码</el-dropdown-item>
          <el-dropdown-item command="outLogin">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!-- 修改密码 -->
    <el-dialog
      width="500px"
      title="修改密码"
      :visible.sync="isChangePass"
      append-to-body
      center
      :close-on-click-modal="false"
    >
      <div class="drawer-content" v-if="form">
        <el-form label-position="top">
          <el-form-item label="旧密码：" required>
            <el-input v-model="form.oldPassword" placeholder="文本输入" />
          </el-form-item>
          <el-form-item label="新密码：" required>
            <el-input v-model="form.newPassword" placeholder="文本输入" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right">
          <el-button @click="isChangePass = false">取消</el-button>
          <el-button type="primary" @click="submitClick">确认</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { systemUserProfileUpdatePassword, notifyMessage } from '@/api/index'
import { ossURL } from '../utils/request'
export default {
  name: 'navbars',
  data() {
    return {
      ossURL,
      isAllRead: true,

      isChangePass: false,
      form: null,
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(this.$store.state.userInfo)
    },
  },
  mounted() {
    this.getNotifyMessage()
  },
  methods: {
    getNotifyMessage() {
      let that = this
      notifyMessage().then((res) => {
        if (res && res.code == 0) {
          that.isAllRead = res.data.state == 0 ? false : true
        }
      })
    },
    handleCommand(e) {
      let that = this
      if (e == 'outLogin') {
        that.$message.success('成功退出!')
        setTimeout(() => {
          that.$router.push('/login')
          that.$store.dispatch('outLoginClick')
        }, 800)
      } else if (e == 'changePassword') {
        this.form = {
          oldPassword: '',
          newPassword: '',
        }
        this.isChangePass = true
      }
    },
    // 确认修改密码
    submitClick() {
      let that = this
      let form = that.form
      if (!form.oldPassword) {
        that.$message.error('请填写旧密码！')
        return false
      }
      if (!form.newPassword) {
        that.$message.error('请填写新密码！')
        return false
      }
      systemUserProfileUpdatePassword(form).then((res) => {
        if (res && res.code == 0) {
          that.$message({
            type: 'success',
            message: '修改成功!',
          })
          that.isChangePass = false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: #f2f7ff;
  padding-right: 20px;
  padding-left: 265px;
  z-index: 1;
  height: 100px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .name {
  font-size: 14px;
  color: #888eb1;
}

.header .right {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header .right .user {
  width: 41px;
  height: 41px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  background: #ffffff;

  img {
    width: 41px;
    height: 41px;
  }
}

.header .right .user .is {
  width: 100%;
  height: 100%;
}

.header .right .icon {
  width: 40px;
  height: 40px;
  background: #fefeff;
  margin-right: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.header .right .icon.on::after {
  content: '';
  border-radius: 50%;
  position: absolute;
  width: 4px;
  height: 4px;
  background: #ff0000;
  right: 10px;
  top: 7px;
}

.header .right .search {
  width: 6.46rem;
  height: 50px;
  margin-right: 0.88rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ced8f4;
}

.header .right .search img {
  display: block;
}

.header .right .search input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  background: none;
  font-size: 14px;
  color: #bcc8df;
  padding-left: 25px;
}
</style>
