import Vue from "vue";
import Vuex from "vuex";
import { loadBMap } from "@/common/map";
import { cityjosn } from "@/utils/city";
import { listSimpleDictDatas } from "@/api/sms/smsChannel";
import { Message } from "element-ui";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    lang: "En",
    baseSrc: "/static/image/",
    footToHome: 0,
    scrollTop: 0,
    clientHeight: 0,
    innerWidth: 0,
    forumTypes: [],
    searchListBoolean: false,
    successNum: 0,
    banners: {},
    openAd: false,
    labels: [],
    dictDatas: {},
    //

    token: null,
    tenantId: null,
    userInfo: null,
    areaList: cityjosn,

    point: {
      province: "",
      city: "",
      latitude: "",
      longitude: "",

      clientHeight: "auto"
    }
  },
  mutations: {
    initClientHeight: (state, data) => {
      state.clientHeight = data;
      window.localStorage.setItem("clientHeight", data);
    },

    // token
    SET_TOKEN: (state, token) => {
      state.token = token;
      window.localStorage.setItem("token", token);
    },
    REMOVE_TOKEN: state => {
      state.token = null;
      window.localStorage.removeItem("token");
    },

    // tenantId
    SET_TENANTID: (state, id) => {
      state.tenantId = id;
      window.localStorage.setItem("tenantId", id);
    },
    REMOVE_TENANTID: state => {
      state.tenantId = null;
      window.localStorage.removeItem("tenantId");
    },

    // userInfo
    SET_USERINFO: (state, data) => {
      state.userInfo = data;
      window.localStorage.setItem("userInfo", data);
    },
    REMOVE_USERINFO: state => {
      state.userInfo = null;
      window.localStorage.removeItem("userInfo");
    },

    //
    SET_DICT_DATAS: (state, dictDatas) => {
      state.dictDatas = dictDatas;
    },

    changePoint(state, point) {
      state.point.province = point.province;
      state.point.city = point.city;
      state.point.latitude = point.latitude;
      state.point.longitude = point.longitude;
    },

    //
    setLang(state, v) {
      state.lang = v;
    },
    setFootToHome(state) {
      state.footToHome += 1;
    },
    setTop(state, v) {
      state.scrollTop = v;
    },
    setHeight(state, v) {
      state.clientHeight = v;
    },
    setWidth(state, v) {
      state.innerWidth = v;
    },
    setForumType(state, v) {
      state.forumTypes = v;
    },
    setSearchList(state, v) {
      state.searchListBoolean = v;
    },
    setSuccessNum(state) {
      state.successNum++;
    },
    clearSuccessNum(state) {
      state.successNum = 0;
    },
    setBanners(state, v) {
      state.banners = v;
    },
    setOpenAd(state, v) {
      state.openAd = v;
    },
    setLabels(state, v) {
      state.labels = v;
    }
  },
  getters: {
    dict_datas: state => state.dictDatas
  },
  actions: {
    // 退出登录
    outLoginClick({ commit }) {
      commit("REMOVE_TOKEN");
      commit("REMOVE_TENANTID");
      commit("REMOVE_USERINFO");
    },
    // 如有缓存直接赋值vuex 不去请求接口
    loadLocal({ commit }) {
      const token = window.localStorage.getItem("token");
      if (token && token != "undefined") {
        commit("SET_TOKEN", token);
      }
      const tenantId = window.localStorage.getItem("tenantId");
      if (tenantId && tenantId != "undefined") {
        commit("SET_TENANTID", tenantId);
      }
      const userInfo = window.localStorage.getItem("userInfo");
      if (userInfo && userInfo != "undefined") {
        commit("SET_USERINFO", userInfo);
      }
      const point = window.localStorage.getItem("point");
      // console.log(point)
      if (point && point != "undefined") {
        commit("changePoint", point);
      }
      const clientHeight = window.localStorage.getItem("clientHeight");
      if (clientHeight && clientHeight != "undefined") {
        commit("initClientHeight", clientHeight);
      }
    },
    getArea({ commit }) {
      let that = this;
      //定义回调函数
      window.initBaiduMapScript = () => {
        that.BMap = window.BMap;
        var geolocation = new that.BMap.Geolocation();
        geolocation.getCurrentPosition(
          function(r) {
            if (this.getStatus() == 0) {
              let pointInfo = {
                province: r.address.province,
                city: r.address.city,
                latitude: r.latitude,
                longitude: r.longitude
              };
              //成功获取位置信息
              commit("changePoint", pointInfo);
              window.localStorage.setItem("point", pointInfo);
            } else {
              //无法获取到位置信息
              Message.error("无法获取到位置信息");
            }
          },
          {
            enableHighAccuracy: true
          }
        );
      };
      //调用脚本获取位置信息
      loadBMap("initBaiduMapScript");
    },
    loadDictDatas({ commit }) {
      listSimpleDictDatas().then(response => {
        // 如果未加载到数据，则直接返回
        if (!response || !response.data) {
          return;
        }
        // 设置数据
        const dictDataMap = {};
        response.data.forEach(dictData => {
          // 获得 dictType 层级
          const enumValueObj = dictDataMap[dictData.dictType];
          if (!enumValueObj) {
            dictDataMap[dictData.dictType] = [];
          }
          // 处理 dictValue 层级
          dictDataMap[dictData.dictType].push({
            value: dictData.value,
            label: dictData.label,
            colorType: dictData.colorType,
            cssClass: dictData.cssClass
          });
        });
        // 存储到 Store 中
        commit("SET_DICT_DATAS", dictDataMap);
      });
    }
  }
});

export default store;
