<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="!$route.meta.nokeep" />
    </keep-alive>
    <router-view v-if="$route.meta.nokeep" />
  </div>
</template>

<script>
import autofit from "autofit.js";
export default {
  name: "App",
  components: {},
  mounted() {
    let addr = this.$store.state.point;
    if (!addr.latitude) {
      this.$store.dispatch("getArea");
    }

    autofit.init({
      designWidth: 1920,
      designHeight: window.innerHeight,
      renderDom: "#app",
      resize: true
    });
  },
  watch: {},
  methods: {}
};
</script>

<style lang="scss">
.el-upload__tip {
  margin-top: 0;
  // color: #ff0000;
}
</style>
