import request from "@/utils/request";

// 更新短信渠道
export function updateSmsChannel(data) {
  return request({
    url: "/admin-api/system/sms-channel/update",
    method: "put",
    data
  });
}

// 获得短信渠道
export function getSmsChannel(id) {
  return request({
    url: "/admin-api/system/sms-channel/get?id=" + id,
    method: "get"
  });
}

// 获得短信渠道分页
export function getSmsChannelPage(params) {
  return request({
    url: "/admin-api/system/sms-channel/page",
    method: "get",
    params
  });
}

// 查询全部字典数据列表
export function listSimpleDictDatas() {
  return request({
    url: "/admin-api/system/dict-data/list-all-simple",
    method: "get"
  });
}

// 获得短信渠道精简列表
export function getSimpleSmsChannels() {
  return request({
    url: '/admin-api/system/sms-channel/list-all-simple',
    method: 'get',
  })
}