import axios from "axios";
import { Message, Loading } from "element-ui";
import store from "@/store";
import router from "@/router";

let loading = null;

// const baseURL = "http://36.41.172.40:48080";
const baseURL = "https://www.sxjgrcw.cn";
// const baseURL = "https://www.dwbaoming.com";
const ossURL = "http://trainingt.oss-cn-beijing.aliyuncs.com/webImg/admin";

const service = axios.create({
  baseURL
});

// request interceptor
service.interceptors.request.use(
  config => {
    let token = store.state.token;
    if (config.noTen == 1) {
    } else {
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      config.headers["tenant-id"] = 1;
    }

    if (!config.noLoading) {
      loading = Loading.service({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
    }
    return config;
  },
  error => {
    console.log("err:", error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  response => {
    loading.close();
    const res = response.data;
    if (res.code === 0 || res.repCode) {
      return res;
    } else if (res.code === 401) {
      Message({
        message: "登录过期，请重新登录！",
        type: "error"
      });
      store.commit("REMOVE_TOKEN");
      store.commit("REMOVE_TENANTID");
      setTimeout(() => {
        router.replace("/login");
      }, 800);
      return;
    } else {
      if (!response.config.isNoFail) Message.error(res.msg);
    }
    return Promise.reject(res.msg);
  },
  error => {
    console.log("err" + error); // for debug
    Message({
      message: error.msg,
      type: "error"
    });
    return Promise.reject(error);
  }
);

const get = (url, params, loading = false) =>
  service({
    method: "get",
    url,
    params,
    loading
  });

const post = (url, params, data, loading = true) =>
  service({
    method: "post",
    url,
    params,
    data,
    loading
  });

const put = (url, params, data, loading = true) =>
  service({
    method: "put",
    url,
    params,
    data,
    loading
  });

const del = (url, params, data, loading = true) =>
  service({
    method: "delete",
    url,
    params,
    data,
    loading
  });

export { get, post, put, del };
export { baseURL, ossURL };
export default service;
